export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';
export const CSV_EXTENSION = '.csv';
export const CSV_TYPE = 'csv';
export const XLSX_TYPE = 'xlsx';
export const TEMPLATE_FILE_TITLE = 'product_import';
export const UPDATE_TEMPLATE_FILE_TITLE = 'update_product';
export const SUPPORTED_FORMATS = [CSV_TYPE, XLSX_TYPE];
export enum ImportType  {
    OPENING_BALANCE_TEMPLATE = "opening-balance-template",
}
